import React from 'react'
import { Descriptions, PageHeader } from 'antd'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import { withAuthorization } from '../Session/Session'
import { compose } from 'recompose'
import { RouteComponentProps } from 'react-router-dom'
import { getSubmission } from '../Firebase/db'
import { FormType } from '../../types/form.type'
import { AddressType } from '../../types/address.type'
import constants from '../../constants'

interface StateProps {
  firebase: Firebase
}

interface State {
  submission: FormType
}

type Props = StateProps & RouteComponentProps

class Submission extends React.Component<Props> {
  state: State = {
    submission: null
  }
  componentDidMount() {
    if ('id' in this.props.match.params) {
      this.load(this.props.match.params['id'])
    }
  }

  load = (id: string) => {
    getSubmission(id).then(data => {
      const submission = data.val()
      if (!submission) return null
      this.setState({ submission })
    })
  }

  render() {
    const { submission } = this.state
    return (
      submission && (
        <>
          <PageHeader
            onBack={() => this.props.history.push(constants.routes.SUBMISSIONS)}
            title="Submission"
            subTitle={submission.businessAddress.formattedAddress}
          />
          <div style={{ padding: '0 25px' }}>
            <Descriptions
              bordered
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {Object.keys(submission).map(
                key =>
                  key !== 'businessAddress' && (
                    <Descriptions.Item label={key} span={3}>
                      {String(submission[key as keyof FormType])}
                    </Descriptions.Item>
                  )
              )}

              {Object.keys(submission.businessAddress).map(key => (
                <Descriptions.Item label={key} span={3}>
                  {submission.businessAddress[key as keyof AddressType]}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
        </>
      )
    )
  }
}

const condition = (authUser: firebase.auth.Auth) => !!authUser

export default compose(
  withFirebase,
  withAuthorization(condition)
)(Submission as any)
