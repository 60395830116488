import React from 'react'
import { Switch, Route } from 'react-router-dom'
import NewSubmission from './components/NewSubmission/NewSubmission'
import Main from './components/Main/Main'
import Signup from './components/Signup/Signup'
import Login from './components/Login/Login'
import constants from './constants'
import Account from './components/Account'
import Submissions from './components/Submissions'
import Forgot from './components/Forgot'
import Submission from './components/Submission'

function Router() {
  return (
    <Switch>
      <Route path={constants.routes.LANDING} exact component={Main} />
      <Route
        path={constants.routes.NEWSUBMISSION}
        exact
        component={NewSubmission}
      />
      <Route path={constants.routes.ACCOUNT} exact component={Account} />
      <Route
        path={constants.routes.SUBMISSIONS}
        exact
        component={Submissions}
      />

      <Route path={constants.routes.SIGN_UP} exact component={Signup} />
      <Route path={constants.routes.SIGN_IN} exact component={Login} />
      <Route path={constants.routes.PASSWORD_FORGET} exact component={Forgot} />
      <Route path={`/submission/:id`} exact component={Submission} />
    </Switch>
  )
}

export default Router
