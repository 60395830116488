import React from 'react'
import {
  GeoCodeResponsePayload,
  PlaceResponcePayload,
  createClient,
  GoogleMapsClient
} from '@google/maps'
import { Select, Spin, Form, message } from 'antd'
import constants from '../../constants'
import { AddressType } from '../../types/address.type'
import { getAddress } from '../../components/Firebase/db'
import { ValidationVarsType } from '../../types/validation.type'

interface PassedProps {
  onSelect?: any
  value: AddressType
}

interface State {
  googleAddresses: google.maps.GeocoderResult[]
  googleAddressesLoading: boolean
  validation: ValidationVarsType
}

type Props = PassedProps

class GoogleAddressSelect extends React.Component<Props> {
  state: State = {
    googleAddresses: [],
    googleAddressesLoading: false,
    validation: {
      status: 'validating',
      hasFeedback: false,
      text: ''
    }
  }

  private mapsClient: GoogleMapsClient

  componentDidMount() {
    this.mapsClient = createClient({ key: constants.googleMapsKey })
    console.log(this.mapsClient)
  }

  fetchPlaceId = (placeId: string) => {
    return fetch(
      `https://maps.googleapis.com/maps/api/place/details/json?key=${
        constants.googleMapsKey
      }&placeid=${placeId}&fields=name,rating,formatted_phone_number`
    )
      .then(async response => response.json())
      .then((json: PlaceResponcePayload) => {
        if (json.results.name) {
          this.props.onSelect('businessName', json.results.name)
        }
      })
  }

  fetchAddressViaPostcode = async (value: any) => {
    if (value.length < 2) {
      return []
    }

    this.setState({ googleAddressesLoading: true })
    /*
    const request: google.maps.GeocoderRequest = {
      address: value,
      region: 'UK'
    }
  
    return this.mapsClient.geocode(request, (error, response) => {
      if (error) {
        this.setState({ googleAddressesLoading: false })
        return []
      }
      console.log(response)
      this.setState({
        googleAddresses: response.json.results,
        googleAddressesLoading: false
      })
    })
    */

    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${value}&region=UK&key=${
        constants.googleMapsKey
      }`
    )
      .then(async response => response.json())
      .then((json: GeoCodeResponsePayload) => {
        if (json.results.length === 0) {
          return []
        }

        this.setState({
          googleAddresses: json.results,
          googleAddressesLoading: false
        })

        return json.results
      })
      .catch(async error => {
        this.setState({ googleAddressesLoading: false })

        return error
      })
  }

  updateAddress = (selectedKey: any) => {
    const validation = { ...this.state.validation }
    validation.status = 'validating'
    validation.hasFeedback = true
    this.setState({ validation })

    const address = this.state.googleAddresses[selectedKey]
    const lng: any = address.geometry.location.lng
    const lat: any = address.geometry.location.lat
    const newSubmissionAddress: AddressType = {
      postCode: this.googleAddressBuilder('postal_code', address),
      route: this.googleAddressBuilder('route', address),
      postalTown: this.googleAddressBuilder('postal_town', address),
      administrativeAreaLevel2: this.googleAddressBuilder(
        'administrative_area_level_2',
        address
      ),
      administrativeAreaLevel1: this.googleAddressBuilder(
        'administrative_area_level_1',
        address
      ),
      country: this.googleAddressBuilder('country', address),
      latitude: lat,
      longitude: lng,
      formattedAddress: address.formatted_address,
      googlePlaceId: address.place_id
    }

    if (newSubmissionAddress.country !== 'United Kingdom') {
      message.error('Country not supported')
      validation.status = 'error'
      validation.hasFeedback = true
      return this.setState({ validation })
    }

    getAddress(newSubmissionAddress.formattedAddress)
      .get()
      .then(snapshot => {
        if (!snapshot.exists) {
          validation.status = 'success'
          validation.hasFeedback = true
          this.setState({ validation })
          if (newSubmissionAddress.googlePlaceId) {
            this.fetchPlaceId(newSubmissionAddress.googlePlaceId)
          }

          if (this.props.onSelect) {
            this.props.onSelect('businessAddress', newSubmissionAddress)
          }
          return true
        }
        message.error('Address in use')
        validation.status = 'error'
        validation.hasFeedback = true
        this.setState({ validation })
      })
  }

  googleAddressBuilder = (
    key: string,
    addressObject: google.maps.GeocoderResult
  ) => {
    const find = addressObject.address_components.find(
      (addressComponent: any) => addressComponent.types.indexOf(key) !== -1
    )

    if (!find) {
      return null
    }

    return find.long_name
  }

  render() {
    const { googleAddresses, googleAddressesLoading, validation } = this.state
    const { value } = this.props
    return (
      <Form.Item
        label="Business Address"
        validateStatus={validation.status}
        hasFeedback={validation.hasFeedback}
        help={validation.text}
        required
      >
        <Select
          size="large"
          showSearch
          value={value ? value.formattedAddress : null}
          style={{ width: '100%' }}
          filterOption={false}
          placeholder="Search address..."
          notFoundContent={
            googleAddressesLoading ? <Spin size="small" /> : null
          }
          onSearch={this.fetchAddressViaPostcode}
          onSelect={this.updateAddress}
        >
          {googleAddresses.map((address, key) => (
            <Select.Option key={key} value={key}>
              {address.formatted_address}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    )
  }
}

export default GoogleAddressSelect
