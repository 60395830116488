import React from 'react'
import { Menu, Icon, Drawer } from 'antd'
import { ClickParam, MenuProps } from 'antd/lib/menu'
import constants from '../../constants'
import { RouteComponentProps, withRouter } from 'react-router'
import { AuthUserContext } from '../Session/Session'
import { compose } from 'recompose'
import Firebase, { withFirebase } from '../Firebase/Firebase'

interface StateProps {
  firebase: Firebase
}

type Props = RouteComponentProps & StateProps

interface State {
  current: string
  drawVisible: boolean
}

class MenuBar extends React.Component<Props> {
  state: State = {
    current: null,
    drawVisible: false
  }

  componentDidMount() {
    this.changeCurrent(this.props.location.pathname)
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname !== this.state.current) {
        this.changeCurrent(this.props.location.pathname)
      }
    }
  }

  toggleDraw = (drawVisible: boolean) => this.setState({ drawVisible })

  changeCurrent = (current: string) => this.setState({ current })

  handleClick = (e: ClickParam) => {
    if (e.key === 'menu') {
      return this.toggleDraw(true)
    }
    if (e.key === 'logout') {
      this.toggleDraw(false)
      return this.props.firebase.doSignOut()
    }
    this.toggleDraw(false)
    this.changeCurrent(e.key)
    this.props.history.push(e.key)
  }

  menuSettings: MenuProps = {
    theme: 'dark',
    mode: 'horizontal',
    selectedKeys: [this.state.current],
    onClick: this.handleClick
  }

  NavAuth = () => (
    <>
      <Menu {...this.menuSettings} selectedKeys={[this.state.current]}>
        <Menu.Item className="Icon" key={'menu'}>
          <Icon type="menu" />
        </Menu.Item>
        <Menu.Item
          style={{ float: 'right' }}
          className="Icon"
          key={constants.routes.NEWSUBMISSION}
        >
          <Icon type="plus-circle" theme="filled" />
        </Menu.Item>
      </Menu>
      <Drawer
        title="Menu"
        placement="left"
        bodyStyle={{ padding: 0 }}
        closable={false}
        onClose={() => this.toggleDraw(false)}
        visible={this.state.drawVisible}
      >
        <Menu
          theme="light"
          mode="vertical"
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
        >
          <Menu.Item key={constants.routes.ACCOUNT}>Account</Menu.Item>
          <Menu.Item key={constants.routes.SUBMISSIONS}>Submissions</Menu.Item>
          <Menu.Item key={'logout'}>Logout</Menu.Item>
        </Menu>
      </Drawer>
    </>
  )

  NavNonAuth = () => (
    <Menu
      theme="dark"
      mode="horizontal"
      onClick={this.handleClick}
      selectedKeys={[this.state.current]}
    >
      <Menu.Item key={constants.routes.SIGN_IN}>Login</Menu.Item>
      <Menu.Item key={constants.routes.SIGN_UP}>Sign Up</Menu.Item>
    </Menu>
  )

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (authUser ? <this.NavAuth /> : <this.NavNonAuth />)}
      </AuthUserContext.Consumer>
    )
  }
}

export default compose(withFirebase, withRouter)(MenuBar as any)
