import React, { Component } from 'react'
import { Form, Input, Button, message, PageHeader } from 'antd'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import FormBody from '../../common/FormBody'
import { Email } from '../../common/Email/Email'
import { RouteComponentProps, Link } from 'react-router-dom'
import constants from '../../constants'

interface StateProps {
  firebase: Firebase
}

interface State {
  loading: boolean
  form: {
    email: string
    password: string
    error: null
  }
}

type Props = StateProps & RouteComponentProps

class Login extends Component<Props> {
  state: State = {
    loading: false,
    form: {
      email: '',
      password: '',
      error: null
    }
  }

  onSubmit = (e: any) => {
    e.preventDefault()
    this.setLoading(true)
    this.props.firebase
      .doSignInWithEmailAndPassword(
        this.state.form.email,
        this.state.form.password
      )
      .then(authUser => {
        console.log(authUser)
        this.setLoading(false)

        message.success('Logged In')
        this.props.history.push(constants.routes.NEWSUBMISSION)
      })
      .catch(error => {
        this.setLoading(false)

        message.error(error.message)
        this.setState({ error })
      })
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  onChange = (key: string, value: any) => {
    const form = { ...this.state.form, [key]: value }
    this.setState({ form })
  }

  render() {
    const { form, loading } = this.state
    return (
      <>
        <PageHeader title="Login" subTitle="Using your email and password" />
        <FormBody>
          <Form onSubmit={this.onSubmit}>
            <Email
              emailKey="email"
              label="Your Email"
              email={form.email}
              callback={this.onChange}
            />
            <Form.Item label="Password" required>
              <Input
                size="large"
                type="password"
                value={form.password}
                onChange={e => this.onChange('password', e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                disabled={loading}
                htmlType="submit"
                size="large"
                type="primary"
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Form>

          <Link to={constants.routes.PASSWORD_FORGET}>Password Reset</Link>
        </FormBody>
      </>
    )
  }
}

export default withFirebase(Login)
