const isLocal = window.location.hostname === 'localhost'

const constants = {
  isLocal,
  api: isLocal ? 'https://api.stage.blackbx.io/' : 'https://api.blackbx.io/',
  form: 'https://form.blackbx.io/',
  environment: 'master',
  zapier: {
    url: 'https://hooks.zapier.com/hooks/catch/886212/oy7vxd0/',
    url1: 'https://hooks.zapier.com/hooks/catch/886212/oyj4hjq/'
  },
  oauth: {
    client_id: isLocal ? 'connect_stage' : 'connect',
    client_secret: '1234',
    redirect_uri: isLocal
      ? 'https://connect.stage.blackbx.io/code'
      : 'https://product.blackbx.io/code'
  },
  firebase: {
    apiKey: 'AIzaSyCzS-snA_SuW2xi8jj2jgurDGUbSd8Ze60',
    authDomain: 'website-206413.firebaseapp.com',
    databaseURL: 'https://website-206413.firebaseio.com',
    projectId: 'website-206413',
    storageBucket: 'website-206413.appspot.com',
    messagingSenderId: '491846042976',
    appId: '1:491846042976:web:a532d989c870b9f2'
  },
  routes: {
    SIGN_UP: '/signup',
    SIGN_IN: '/login',
    LANDING: '/',
    HOME: '/home',
    ACCOUNT: '/account',
    ADMIN: '/admin',
    PASSWORD_FORGET: '/forgot',
    NEWSUBMISSION: '/new-submission',
    SUBMISSIONS: '/submissions'
  },
  googleMapsKey: 'AIzaSyCzS-snA_SuW2xi8jj2jgurDGUbSd8Ze60'
}

export default constants
