import axios from 'axios'
import Constants from '../constants'
import storage from './storage'
import { FormType } from '../types/form.type'

declare global {
  interface Window {
    $zoho: any
    FS: any
  }
}

const studentEmail = storage.get('student_email')

const apiInstance = axios.create({
  baseURL: Constants.api,
  timeout: 100000,
  maxContentLength: 2000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const sendToZoho = (_payload: FormType): any => {
  /*
  if (!window.$zoho) return false
  if (payload.email) {
    window.$zoho.salesiq.visitor.email(payload.email)
  }
  if (payload.first && payload.last) {
    window.$zoho.salesiq.visitor.name(`${payload.first} ${payload.last}`)
  }
  if (payload.phone) {
    window.$zoho.salesiq.visitor.contactnumber(payload.phone)
  }
  if (payload.uid) {
    window.$zoho.salesiq.visitor.id(payload.uid)
  }
  if (payload.website) {
    window.$zoho.salesiq.visitor.info({
      website: payload.website,
      company: payload.company,
      vendor: payload.vendor
    })
  }
  window.$zoho.salesiq.chat.complete()

  if (!window.FS) return false

  window.FS.identify(payload.uid, {
    displayName: `${payload.first} ${payload.last}`,
    email: payload.email
  })
  */
}

export const api = {
  updateAccount(payload: FormType, _email: string): any {
    const zapierPayload = { ...payload }
    zapierPayload.studentEmail = studentEmail
    return sendToZoho(zapierPayload)
  },
  validate: {
    phone(number: string) {
      return apiInstance
        .get('sms', {
          params: {
            phone: number
          }
        })
        .then(response => response.data)
    },
    email(email: string) {
      return apiInstance
        .post('public/validation/email', {
          email: email
        })
        .then(response => response.data)
    }
  }
}
