import app from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'

import { FormType } from '../../types/form.type'
import { AccountType } from '../../types/account.type'

const db = app.database()
const store = app.firestore()

export const doUpdateUser = (id: string, account: AccountType) =>
  db.ref(`users/${id}/profile`).update(account)

export const doCreateSubmission = (id: string, submission: FormType) => {
  submission.uid = id
  return db.ref(`submissions`).push(submission)
}

export const getSubmissions = (id: string) =>
  db
    .ref(`submissions`)
    .orderByChild('uid')
    .equalTo(id)
    .once('value')

export const getSubmission = (id: string) =>
  db.ref(`submissions/${id}`).once('value')

export const getUser = (id: string) =>
  db.ref(`users/${id}/profile`).once('value')

export const storeAddresses = () => store.collection('addresses')

export const getAddress = (formattedAddress: string) =>
  storeAddresses().doc(formattedAddress)
