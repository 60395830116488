import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'

import AuthUserContext from './context'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import constants from '../../constants'

interface StateProps {
  firebase: Firebase
}

type Props = StateProps & RouteComponentProps

const withAuthorization = (condition: any) => (Component: any) => {
  class WithAuthorization extends React.Component<Props> {
    componentDidMount() {
      this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          this.props.history.push(constants.routes.SIGN_IN)
        }
      })
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(
    withRouter,
    withFirebase
  )(WithAuthorization as any)
}

export default withAuthorization
