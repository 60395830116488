import React from 'react'
import './App.css'
import Router from '../../routes'
import { withAuthentication } from '../Session/Session'
import { Layout } from 'antd'
import Menu from '../Menu'

const { Header, Content } = Layout

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}
// Detects if device is in standalone mode
const isInStandaloneMode = () =>
  window.matchMedia('(display-mode: standalone)').matches

interface State {
  notch: boolean
}

class App extends React.Component {
  state: State = {
    notch: false
  }

  componentDidMount() {
    window.onload = () => this.handleNotch()
  }

  handleNotch = () => {
    if (isIos() && isInStandaloneMode()) {
      const body = document.body

      body.classList.add('notch')
      return this.setState({ notch: true })
    }

    return this.setState({ notch: false })
  }
  render() {
    return (
      <Layout className={`layout`}>
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <div className="logo">Prospect</div>
          <Menu />
        </Header>

        <Content style={{ marginTop: this.state.notch ? 128 : 64 }}>
          <Router />
        </Content>
      </Layout>
    )
  }
}

export default withAuthentication(App)
