import React from 'react'
import { Layout, Row, Col } from 'antd'

class FormBody extends React.Component {
  render() {
    return (
      <Layout className="FormBody">
        <Row>
          <Col xs={24} sm={{ offset: 8, span: 8 }}>
            {this.props.children}
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default FormBody
