import React from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  Icon,
  message,
  PageHeader,
  Checkbox
} from 'antd'
import { FormType } from '../../types/form.type'
import { Email } from '../../common/Email/Email'
import businessType from '../../store/businessTypes.json'
import ageRanges from '../../store/ageRange.json'
import FormBody from '../../common/FormBody'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import { doCreateSubmission, getAddress } from '../Firebase/db'
import GoogleAddressSelect from '../../common/Address/Address'
import { uploadImage } from '../Firebase/upload'
import { compose } from 'recompose'
import { withAuthorization } from '../Session/Session'

interface State {
  form: FormType
  loading: boolean
}

interface StateProps {
  firebase: Firebase
}

type Props = StateProps

const initialState: FormType = {
  studentEmail: null,
  businessAddress: null,
  businessAgeRange: null,
  businessEmail: null,
  businessName: null,
  businessType: null,
  otherComments: null,
  socialMediaFollowers: null,
  hasNewsletter: false,
  loyaltyScheme: false,
  onlineShop: false,
  doesMarketing: false,
  onlineRating: null,
  files: [],
  date: new Date().getTime(),
  valid: false,
  uid: null
}

const CheckStyle = {
  margin: 0,
  display: 'block',
  lineHeight: '25px'
}

class NewSubmission extends React.Component<Props> {
  state: State = {
    loading: false,
    form: { ...initialState }
  }

  changeInput = (key: string, value: any) => {
    const form = { ...this.state.form, [key]: value }
    console.log(key, value, 'checkchange')
    this.setState({ form })
  }

  submit = () => {
    const form = { ...this.state.form }
    form.studentEmail = this.props.firebase.auth.currentUser.email
    doCreateSubmission(this.props.firebase.uid(), form).then(() => {
      getAddress(this.state.form.businessAddress.formattedAddress)
        .set(this.state.form.businessAddress, { merge: true })
        .then(() => {
          this.setState({ form: { ...initialState } })
          message.success('Submission added')
        })
    })
  }

  upload = (info: any) => {
    const form = { ...this.state.form }
    const files = form.files
    const image = uploadImage(this.props.firebase.uid(), info.file)
    console.log(info)
    image
      .put(info.file)
      .then(() => {
        image.getDownloadURL().then((url: string) => {
          files.push(url)
          this.setState({ form })
          info.onSuccess(null, info.file)
        })
        message.success('Image uploaded')
      })
      .catch(() => info.onError(null))
  }

  disabled = () => {
    const { form } = this.state
    if (!form.businessAddress) return true
    if (!form.businessAgeRange) return true
    if (!form.businessName) return true
    if (!form.businessType) return true
    if (form.files.length <= 1) return true
    return false
  }

  render() {
    const { form, loading } = this.state
    return (
      <>
        <PageHeader
          title="New Prospect"
          subTitle="Tell us about the business you are in"
        />

        <FormBody>
          <Form>
            <GoogleAddressSelect
              value={form.businessAddress}
              onSelect={this.changeInput}
            />
            {form.businessAddress && (
              <>
                <Form.Item label="Business Name" required>
                  <Input
                    size="large"
                    value={form.businessName}
                    onChange={e =>
                      this.changeInput('businessName', e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item label="Business Type" required>
                  <Select
                    size="large"
                    showSearch
                    value={form.businessType}
                    style={{ width: '100%' }}
                    placeholder=""
                    optionFilterProp="children"
                    onChange={(v: string) =>
                      this.changeInput('businessType', v)
                    }
                  >
                    {businessType.map((business, key) => (
                      <Select.Option key={key} value={business}>
                        {business}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Age Demographic" required>
                  <Select
                    size="large"
                    showSearch
                    value={form.businessAgeRange}
                    style={{ width: '100%' }}
                    placeholder=""
                    optionFilterProp="children"
                    onChange={(v: string) =>
                      this.changeInput('businessAgeRange', v)
                    }
                  >
                    {ageRanges.map((age, key) => (
                      <Select.Option key={key} value={age}>
                        {age}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Social Media Followers" required>
                  <Input
                    size="large"
                    value={form.socialMediaFollowers}
                    type="number"
                    onChange={e =>
                      this.changeInput('socialMediaFollowers', e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Online Rating (Google / Trip Advisor"
                  required
                >
                  <Input
                    size="large"
                    value={form.onlineRating}
                    type="number"
                    onChange={e =>
                      this.changeInput('onlineRating', e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item label="Upload image of WiFi experience" required>
                  <Upload
                    openFileDialogOnClick={true}
                    name="file"
                    listType="picture"
                    showUploadList={true}
                    customRequest={options => this.upload(options)}
                    multiple={false}
                  >
                    <Button block>
                      <Icon type="upload" /> Upload
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item label="Upload image of business" required>
                  <Upload
                    openFileDialogOnClick={true}
                    name="file"
                    listType="picture"
                    showUploadList={true}
                    customRequest={options => this.upload(options)}
                    multiple={false}
                  >
                    <Button block>
                      <Icon type="upload" /> Upload
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item label="Business Has" required>
                  <Checkbox
                    style={CheckStyle}
                    value={form.loyaltyScheme}
                    onChange={e =>
                      this.changeInput('loyaltyScheme', e.target.checked)
                    }
                  >
                    Loyalty Scheme
                  </Checkbox>
                  <Checkbox
                    style={CheckStyle}
                    value={form.hasNewsletter}
                    onChange={e =>
                      this.changeInput('hasNewsletter', e.target.checked)
                    }
                  >
                    Existing mailing list/newsletter
                  </Checkbox>
                  <Checkbox
                    style={CheckStyle}
                    value={form.onlineShop}
                    onChange={e =>
                      this.changeInput('onlineShop', e.target.checked)
                    }
                  >
                    Online shop - yes/no
                  </Checkbox>
                  <Checkbox
                    style={CheckStyle}
                    value={form.doesMarketing}
                    onChange={e =>
                      this.changeInput('doesMarketing', e.target.checked)
                    }
                  >
                    Marketing activities
                  </Checkbox>
                </Form.Item>

                <Email
                  emailKey="businessEmail"
                  label="Business Email"
                  email={form.businessEmail}
                  callback={this.changeInput}
                />

                <Form.Item label="Any Other Comments">
                  <Input.TextArea
                    value={form.otherComments}
                    onChange={e =>
                      this.changeInput('otherComments', e.target.value)
                    }
                    rows={5}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={this.submit}
                    loading={loading}
                    disabled={loading || this.disabled()}
                    size="large"
                    type="primary"
                    block
                  >
                    Send
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </FormBody>
      </>
    )
  }
}

const condition = (authUser: firebase.auth.Auth) => !!authUser

export default compose(
  withFirebase,
  withAuthorization(condition)
)(NewSubmission as any)
