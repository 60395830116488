import React from 'react'
import { Table, PageHeader, Icon, Tooltip } from 'antd'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import { getSubmissions } from '../Firebase/db'
import { FormType } from '../../types/form.type'
import { withAuthorization } from '../Session/Session'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'

interface StateProps {
  firebase: Firebase
}

interface State {
  submissions: FormType[]
  loading: boolean
}

class Submissions extends React.Component<StateProps> {
  state: State = {
    submissions: [],
    loading: false
  }

  componentDidMount() {
    const uid = this.props.firebase.uid()
    if (uid) {
      this.load(uid)
    }
  }

  componentDidUpdate(prevProps: StateProps) {
    const uid = this.props.firebase.uid()
    if (uid && !prevProps.firebase.uid() && !this.state.loading) {
      this.load(uid)
    }
  }

  load = (uid: string) => {
    this.setState({ loading: true })
    getSubmissions(uid).then(data => {
      const value = data.val()
      this.setState({ loading: false })
      if (!value) return []
      const submissions: FormType[] = Object.keys(value).map(key => {
        value[key].id = key
        return value[key]
      })
      submissions.sort((a, b) => b.date - a.date)
      this.setState({ submissions })
    })
  }

  columns = [
    {
      render: (submission: FormType) => {
        if (submission.valid) {
          return (
            <Tooltip placement="right" title="Prospect aproved">
              <Icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </Tooltip>
          )
        }
        return (
          <Tooltip
            placement="right"
            title="Pending aproval from a member of the team"
          >
            <Icon type="close-circle" theme="twoTone" twoToneColor="red" />
          </Tooltip>
        )
      }
    },
    {
      title: 'Name',
      render: (submission: FormType) => (
        <Link to={`/submission/${submission.id}`}>
          {submission.businessName}
        </Link>
      )
    },
    {
      title: 'Address',
      render: (submission: FormType) => {
        if (!submission.businessAddress) return null
        return <span>{submission.businessAddress.formattedAddress}</span>
      }
    },
    {
      title: 'Type',
      dataIndex: 'businessType',
      key: 'businessType',
      render: (type: string) => <span>{type}</span>
    }
  ]

  render() {
    const { submissions, loading } = this.state
    return (
      <>
        <PageHeader
          title="Submissions"
          subTitle="Previous submissions made by you"
        />

        <Table
          rowKey="id"
          loading={loading}
          columns={this.columns}
          dataSource={submissions}
          pagination={false}
        />
      </>
    )
  }
}

const condition = (authUser: firebase.auth.Auth) => !!authUser

export default compose(
  withFirebase,
  withAuthorization(condition)
)(Submissions as any)
