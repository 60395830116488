import app from 'firebase/app'
import 'firebase/auth'
import constants from '../../constants'

app.initializeApp(constants.firebase)

class FirebaseInit {
  auth: firebase.auth.Auth = null

  constructor() {
    this.auth = app.auth()
  }

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = (password: string) =>
    this.auth.currentUser.updatePassword(password)

  uid = () => {
    if (!this.auth) {
      return null
    }
    if (!this.auth.currentUser) {
      return null
    }
    return this.auth.currentUser.uid
  }
}

export default FirebaseInit
