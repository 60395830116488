import React from 'react'

import AuthUserContext from './context'
import Firebase, { withFirebase } from '../Firebase/Firebase'

interface StateProps {
  firebase: Firebase
}

interface State {
  authUser: any
}

const withAuthentication = (Component: any) => {
  class WithAuthentication extends React.Component<StateProps> {
    state: State = {
      authUser: null
    }

    componentDidMount() {
      this.props.firebase.auth.onAuthStateChanged(authUser => {
        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null })
      })
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
