import React, { Component } from 'react'
import { Form, Input } from 'antd'
import { ValidationVarsType } from '../../types/validation.type'
import { api } from '../../utils/api'

interface PassedProps {
  email: string
  callback?: any
  emailKey: string
  label: string
}

interface State {
  validation: ValidationVarsType
  email: string
}

export class Email extends Component<PassedProps> {
  state: State = {
    email: '',
    validation: {
      status: 'validating',
      hasFeedback: false,
      text: ''
    }
  }

  validateEmailRegex = (email: string) => {
    //eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  validateEmail = (email: string) => {
    const validation = { ...this.state.validation }
    validation.status = 'validating'
    validation.hasFeedback = true
    this.setState({ validation })
    if (!this.validateEmailRegex(email)) {
      validation.status = 'warning'
      return this.setState({ validation })
    }
    api.validate
      .email(email)
      .then(response => {
        if (response.status === 200) {
          validation.status = 'success'
          validation.hasFeedback = true
          this.setState({ validation })
          this.props.callback(this.props.emailKey, this.state.email)
        }
      })
      .catch(error => {
        if (error.response.data.status === 400) {
          validation.status = 'error'
          validation.hasFeedback = true
          this.setState({ validation })
          this.props.callback(this.props.emailKey, null)
        }
      })
  }

  changeInput = (email: string) => {
    this.validateEmail(email)
    this.setState({ email })
  }

  render() {
    const { validation, email } = this.state
    return (
      <Form.Item
        required={this.props.emailKey !== 'businessEmail'}
        validateStatus={validation.status}
        hasFeedback={validation.hasFeedback}
        help={validation.text}
        label={this.props.label}
      >
        <Input
          onBlur={event => this.changeInput(event.target.value)}
          type="email"
          size="large"
          value={email}
          onChange={event => this.changeInput(event.target.value)}
        />
      </Form.Item>
    )
  }
}
