import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import * as serviceWorker from './serviceWorker'
import Firebase, { FirebaseContext } from './components/Firebase/Firebase'
import /* webpackPrefetch: true */ 'typeface-montserrat'
import App from './components/App/App'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FirebaseContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

serviceWorker.isUpdateAvailable.then((isAvaliable: boolean) => {
  if (isAvaliable) {
    alert('update')
  }
})
