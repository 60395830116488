import React from 'react'
import { Form, Input, Row, Col, Button, message, PageHeader } from 'antd'
import { AccountType } from '../../types/account.type'
import FormBody from '../../common/FormBody'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import { doUpdateUser, getUser } from '../Firebase/db'
import { compose } from 'recompose'
import { withAuthorization } from '../Session/Session'

interface StateProps {
  firebase: Firebase
}

interface State {
  form: AccountType
}

type Props = StateProps

class Account extends React.Component<Props> {
  state: State = {
    form: {
      firstname: null,
      lastname: null,
      paypal: null
    }
  }

  componentDidMount() {
    if (this.props.firebase.uid()) {
      this.load(this.props.firebase.uid())
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.firebase.uid() && this.props.firebase.uid()) {
      this.load(this.props.firebase.uid())
    }
  }

  load = (uid: string) => {
    getUser(uid).then(data => {
      const value = data.val()
      if (!value) return
      const form: AccountType = data.val()
      this.setState({ form })
    })
  }

  changeInput = (key: string, value: string) => {
    const form = { ...this.state.form, [key]: value }
    this.setState({ form })
  }

  submit = () =>
    doUpdateUser(this.props.firebase.uid(), this.state.form).then(() =>
      message.success('Account updated')
    )

  render() {
    const { form } = this.state
    return (
      <>
        <PageHeader title="Account" subTitle="Information about you" />
        <FormBody>
          <Form>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item label="Firstname">
                  <Input
                    size="large"
                    value={form.firstname}
                    onChange={e =>
                      this.changeInput('firstname', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Lastname">
                  <Input
                    size="large"
                    value={form.lastname}
                    onChange={e => this.changeInput('lastname', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Paypal Account">
              <Input
                size="large"
                value={form.paypal}
                onChange={e => this.changeInput('paypal', e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={this.submit} size="large" type="primary" block>
                Save
              </Button>
            </Form.Item>
          </Form>
        </FormBody>
      </>
    )
  }
}

const condition = (authUser: firebase.auth.Auth) => !!authUser

export default compose(
  withFirebase,
  withAuthorization(condition)
)(Account as any)
