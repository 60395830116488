import React, { Component } from 'react'
import { Form, Input, Button, message, Row, Col, PageHeader } from 'antd'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import { Email } from '../../common/Email/Email'

import constants from '../../constants'
import { RouteChildrenProps } from 'react-router'
import { doUpdateUser } from '../Firebase/db'

interface StateProps {
  firebase: Firebase
}

interface State {
  form: {
    paypal: string
    firstname: string
    lastname: string
    email: string
    password: string
    error: null
  }
  loading: boolean
}

type Props = StateProps & RouteChildrenProps

class Signup extends Component<Props> {
  state: State = {
    loading: false,
    form: {
      firstname: null,
      lastname: null,
      paypal: null,
      email: '',
      password: '',
      error: null
    }
  }

  onSubmit = () => {
    this.setLoading(true)
    const { form } = this.state
    this.props.firebase
      .doCreateUserWithEmailAndPassword(form.email, form.password)
      .then((authUser: firebase.auth.UserCredential) => {
        console.log(authUser.user.uid)

        doUpdateUser(authUser.user.uid, {
          paypal: form.paypal,
          firstname: form.firstname,
          lastname: form.lastname
        }).then(() => {
          this.setLoading(false)
          message.success('Account created')
          this.props.history.push(constants.routes.LANDING)
        })
      })
      .catch(error => {
        this.setLoading(false)
        message.warn(error.message)
        this.setState({ error })
      })
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  onChange = (key: string, value: any) => {
    const form = { ...this.state.form, [key]: value }
    this.setState({ form })
  }

  render() {
    const { form, loading } = this.state
    return (
      <>
        <PageHeader title="Sign Up" subTitle="Lets get you setup" />

        <Row className="FormBody" style={{ padding: '0 25px' }} gutter={25}>
          <Col sm={8}>
            <h2>
              Get paid £5 for visiting and telling us about WiFi in venues
            </h2>
          </Col>
          <Col sm={8}>
            <Form>
              <Email
                emailKey="email"
                label="Your Email"
                email={form.email}
                callback={this.onChange}
              />
              <Form.Item label="Password">
                <Input
                  size="large"
                  type="password"
                  value={form.password}
                  onChange={e => this.onChange('password', e.target.value)}
                />
              </Form.Item>
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item label="Firstname">
                    <Input
                      size="large"
                      value={form.firstname}
                      onChange={e => this.onChange('firstname', e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Lastname">
                    <Input
                      size="large"
                      value={form.lastname}
                      onChange={e => this.onChange('lastname', e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Paypal Account">
                <Input
                  size="large"
                  value={form.paypal}
                  onChange={e => this.onChange('paypal', e.target.value)}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  disabled={loading}
                  onClick={this.onSubmit}
                  size="large"
                  type="primary"
                  block
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

export default withFirebase(Signup)
