import React, { Component } from 'react'
import { Form, Button, message, PageHeader } from 'antd'
import Firebase, { withFirebase } from '../Firebase/Firebase'
import FormBody from '../../common/FormBody'
import { Email } from '../../common/Email/Email'
import { RouteComponentProps } from 'react-router-dom'
import constants from '../../constants'

interface StateProps {
  firebase: Firebase
}

interface State {
  loading: boolean
  form: {
    email: string

    error: null
  }
}

type Props = StateProps & RouteComponentProps

class Forgot extends Component<Props> {
  state: State = {
    loading: false,
    form: {
      email: '',
      error: null
    }
  }

  onSubmit = () => {
    this.setLoading(true)
    this.props.firebase
      .doPasswordReset(this.state.form.email)
      .then(() => {
        this.setLoading(false)

        message.success('An email has been sent to reset your password')
        this.props.history.push(constants.routes.SIGN_IN)
      })
      .catch(error => {
        this.setLoading(false)
        this.setState({ error })
        message.error('We could not find your account')
      })
  }

  setLoading = (loading: boolean) => this.setState({ loading })

  onChange = (key: string, value: any) => {
    const form = { ...this.state.form, [key]: value }
    this.setState({ form })
  }

  render() {
    const { form, loading } = this.state
    return (
      <>
        <PageHeader title="Forgot" subTitle="Happens more than you'd think" />
        <FormBody>
          <Form>
            <Email
              emailKey="email"
              label="Your Email"
              email={form.email}
              callback={this.onChange}
            />

            <Form.Item>
              <Button
                loading={loading}
                disabled={loading}
                onClick={this.onSubmit}
                size="large"
                type="primary"
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </FormBody>
      </>
    )
  }
}

export default withFirebase(Forgot)
