import React from 'react'

import { withAuthorization } from '../Session/Session'

class Main extends React.Component {
  render() {
    return <span>Welcome</span>
  }
}

const condition = (authUser: firebase.auth.Auth) => !!authUser

export default withAuthorization(condition)(Main)
